<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 pt-4>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Transaction ID</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Ticket</th>
                <th class="text-left">Phone</th>
                <!-- <th class="text-left">Name</th> -->
                <th class="text-left">Amount(INR)</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in reportData" :key="i">
                <td>{{ item.txnid }}</td>
                <td>{{ item.init_date.slice(0, 10) }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      'https://api.wayanadwildlifesanctuary.com/booking/ticketpdf/?id=' +
                      item._id
                    "
                    style="color: #68d389"
                  >
                    <b> #{{ item.ticket.ticketNo }}</b>
                  </a>
                </td>
                <td>{{ item.user.phone }}</td>
                <!-- <td>{{ item.name }}</td> -->
                <td>{{ item.total }}</td>
                <td>
                  <div :class="item.status == 'Cancelled' ? 'line1' : ''">
                    <span
                      v-if="item.status == 'Cancelled'"
                      style="
                        color: white;
                        font-size: 13.5px;
                        font-family: poppinssemibold;
                        letter-spacing: 2px;
                        background-color: red;
                        padding-right: 20px;
                        padding-left: 20px;
                      "
                    >
                      Cancelled
                    </span>
                  </div>
                  <v-layout wrap justify-start>
                    <v-flex xs12 align-self-center lg4 pt-6>
                      <!-- :disabled="item.verificationStatus == 'Verified'" -->
                      <v-select
                        v-model="item.verificationStatus"
                        :items="verifyStatus"
                        item-text="name"
                        item-value="value"
                        item-color="#8d8d8d"
                        color="#8d8d8d"
                        outlined
                        dense
                        label="Status"
                        @change="askStatus(item._id, item.verificationStatus)"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </td>
                <!-- <div
                  :class="
                    item.status == 'Cancelled' ? 'line1' : ''
                  "
                >
                  <span
                    v-if="item.status == 'Cancelled'"
                    style="
                      color: white;
                      font-size: 13.5px;
                      font-family: poppinssemibold;
                      letter-spacing: 2px;
                      background-color: red;
                      padding-right: 20px;
                      padding-left: 20px;
                    "
                  >
                    Cancelled
                  </span>
                </div> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title style="font-family: poppinssemibold; font-size: 16px"
          >Are you sure you want to change current Status?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="getData(), (updateDialog = false)"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="updateStatus(dialogId, dialogStatus)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["bookingData", "pages", "dates", "filter"],
  // props: ["fromDate","toDate","programme"],
  data() {
    return {
      currentPage: 1,
      // pages: 0,
      // bookingData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      verify: null,
      verification: [],
      updateDialog: false,
      dialogId: null,
      dialogStatus: null,
      verifyStatus: ["Verified", "Not Verified"],
      reportData: [],
      totalPage: 0,
    };
  },
  // watch: {
  //   currentPage() {
  //     this.winStepper();
  //   },
  // },

  watch: {
    bookingData() {
      this.reportData = this.bookingData;
      this.totalPage = this.pages;
    },
    filter() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.winStepper();
    },
    dates() {
      if (this.currentPage > 1) this.currentPage = 1;
      // this.getData()
    },
    toDate() {
      // this.getData()
    },
    programme() {
      // this.getData()
    },
    currentPage() {
      // this.getData();
      this.winStepper();
    },
  },
  beforeMount() {
    this.getData();
    this.winStepper();
  },
  methods: {
    winStepper() {
      // console.log("step", this.currentPage);
      // alert("my call win comp")
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.totalPage,
      });
    },
    askStatus(id, status) {
      this.updateDialog = true;
      this.dialogId = id;
      this.dialogStatus = status;
      // this.updateStatus(id, status)
      // console.log(this.dialogId,"km",this.dialogStatus)
    },
    updateStatus(id, status) {
      // console.log(id,"dfgkm",status)
      // alert(status);
      this.updateDialog = false;
      axios({
        url: "/report/updateverificationstatus",
        method: "POST",
        data: { id: id, status: status },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.dialogId = null;
            this.dialogStatus = null;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      // console.log(this.dates)
      // console.log("Child getData",this.currentPage);
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/report/bookingreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          date: this.dates,
          status: this.filter,
          // to: this.toDate,
          programme: this.programme,
          slotDetail: this.slotDetail,
          count: 12,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.reportData = response.data.data;
            // this.reservationData = response.data.reservations;
            this.totalPage = response.data.pages;

            // this.excel = response.data.data;
            // this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
/* .line1 {
  width: 481px;
  height: 99px;
  z-index: 2;
  border-bottom: 0px solid white;
  -webkit-transform: translateY(0px) translateX(0px) rotate(-26deg);
  position: absolute;
  top: 79px;
  left: -45px;
} */
.line1 {
  width: auto;
  height: 0;
  z-index: 2;
  border-bottom: 0px solid white;
  -webkit-transform: translateY(-6px) translateX(-10px) rotate(-20deg);

  /* position: relative; */
  top: 0px;
  left: -45px;
}
</style>